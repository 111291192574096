import React from 'react'
import { List, ListItem} from '@material-ui/core';
import { ethers } from 'ethers';

export default function FunctionList(props: any) {
    const [farr, setFarr] = React.useState([] as any[])

    React.useEffect(() => {
        if (!props.functions)
            return
        setFarr(props.functions)
    }, [props.functions])

    const special = ['mint', 'postNewChallenges', 'getAllMessagesText', 'addMessage', 'sponsor', 'svg', 'tokenURI', 'bySolver', 'byOwner']

    return <>
        <List>
            {
                farr.map((f: any, farr_index:number) => {
                    // return <ListItem key={ethers.utils.id(f.name)}>
                    return <ListItem key={ethers.utils.id(`${f}:${farr_index}`)}>
                        <div id={special.includes(f.name) ? "special-function-option":"normal-function-option"} className="function-option" onClick={() => props.onSelect(f.name)}>
                            {f.name}
                        </div>
                    </ListItem>
                })
            }
        </List>
    </>
}