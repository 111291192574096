import ContractDetailsRecord from './classes/ContractDetailsRecord';
import ContractData from './classes/ContractData';
import contractOptions from './constants/contractOptions';
import { ethers } from 'ethers';

const _contractOptions: ContractDetailsRecord[] = contractOptions.map((c: ContractData) => ({
  label: `${c.contractName} on ${c?.blockchain?.name} (${c.address})`,
  value: c,
  key: ethers.utils.id(JSON.stringify(c)),
}));

export default _contractOptions;