import { createSlice } from '@reduxjs/toolkit'

function makeSafe(unsafe:any) {
  return unsafe.map((item:any) => {
    return {
           challenger: item.challenger,
           clue_size: item.clue_size,
           difficulty: item.difficulty,
           hint: item.hint,
           index: item.index,
           pubkey: item.pubkey,
           reward: item.reward,
           position: item.position,
    }
  });
}

const challengeDisplaySlice = createSlice({
    name: 'challengeDisplaySlice', 
    initialState: {
        lookup: [],
    },
    reducers: {
        setLookup: (state: any, action:any) => {
            const safe = makeSafe(action.payload)
            state.lookup = safe
        }
    }
})

export default challengeDisplaySlice
export const {setLookup} = challengeDisplaySlice.actions