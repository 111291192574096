
import { createSlice } from '@reduxjs/toolkit'

const solutionSlice = createSlice({
    name: 'solutionSlice',
    initialState: {
        solution : null,
        show: false,
    },
    reducers: {
        setSolution: (state:any, action:any) => {
            state.solution = action.payload
        },
        setShow: (state:any, action:any) => {
            state.show = action.payload
        }
    }
})
export default solutionSlice
export const { setSolution, setShow } = solutionSlice.actions