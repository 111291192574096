import React from "react"
import { ethers } from "ethers";
import DataRow from "../../classes/DataRow";
import RenderSvg from "../RenderSvg/RenderSvg";
import * as elliptic from "elliptic"
import bn from "bn.js"
import count_unknown_digit from "../../functions/count_unknown_digit";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector, useDispatch } from 'react-redux'
import * as sponsorModalState from "../../redux/slices/sponsorModalSlice"
import calculateScale from "../../functions/calculateScale";
import WorkingState from "../WorkController/WorkingState";
import Dev from "../../classes/Dev";
import currency_symbol from "../../functions/currency_symbol";
import isAlreadySolved from "../../functions/isAlreadySolved";
import eth_pub_to_std_pub from "../../functions/eth_pub_to_std_pub";
import { setShow, setSolution } from '../../redux/slices/solutionSlice'
import LogEvent from "../../functions/LogEvent";
import ChallengePreviewProxy from "./Proxy";
import "./Styles.css"

const ec = new elliptic.ec('secp256k1')

const __expected_pub_key_length__ = 132

export default function ChallengePreview({ elId, data }: { elId: number, data: any }) {
    const [challengeDataToShow, setChallengeDataToShow] = React.useState<DataRow | null>(null)
    const [balance, setBalance] = React.useState<string>('0')
    const [clue, setClue] = React.useState<string>('Loading...')

    const lookup = useSelector((state: any) => state.challengeDisplay.lookup)
    const cd = useSelector((state: any) => state.contractData.contractData) // contract data

    const dispatch = useDispatch()

    const [workState, setWorkState] = React.useState<WorkingState>(WorkingState.Idle)
    const w = React.useRef<Worker | null>(null)
    const w_internal_state = React.useRef<any>(null)

    React.useEffect(() => {
        if (w.current === null) {
            w.current = new Worker(`worker.js`)
            setWorkState(WorkingState.Warmup)
        }
    }, [])

    React.useEffect(() => {
        if (challengeDataToShow === null)
            return

        if (challengeDataToShow.hint.length < 16)
            return

        const hint_bn = new bn(challengeDataToShow.hint.substring(2), 16)
        const hint_bin = hint_bn.toString(2).padEnd(256, '0')
        setClue(hint_bin)
    }, [challengeDataToShow])

    /**
        * pauseWork
        * August 3rd 2022
        * William Doyle
        */
    function pauseWork() {
        w.current?.terminate()
        w.current = null
        setWorkState(WorkingState.Paused)
        Dev.log(w_internal_state.current)
    }

    /**
     * init_worker()
     * August 3rd 2022
     * William Doyle
     */
    function init_worker() {
        if (challengeDataToShow === null)
            return // no challenge to work on

        if (w.current === null) {
            Dev.log(`to early`)
            return
        }

        if (workState === WorkingState.Warmup) {
            w.current.postMessage(JSON.stringify({
                header: {
                    type: 'solve puzzle'
                },
                pub: challengeDataToShow.pubkey,
                hint: challengeDataToShow.hint,
                cd: cd,
                position: challengeDataToShow.position,
                state: {
                    i: 0,
                    hash: ''
                }
            }))
            w.current.onmessage = event => {
                Dev.log(`Message ${event.data}`)

                const data = JSON.parse(event.data)
                const msg_type: string = data.header.type

                switch (msg_type) {
                    case 'solution':
                        w.current?.terminate()              // terminate the thread
                        setWorkState(WorkingState.Idle)
                        const solution = data.solution
                        dispatch(setSolution({              // show celebration animation
                            solution: solution,
                            position: challengeDataToShow.position,
                            challengeDataToShow: challengeDataToShow,
                        }))
                        dispatch(setShow(true))
                        break;
                    case 'death request':
                        Dev.log(`reacting to death request`)
                        w.current?.terminate()
                        setWorkState(WorkingState.Idle)
                        break
                    default:
                        Dev.log(`unknown case `)
                }
            };
        }
    }

    /**
     * loadWorkerState()
     * Sends a message to the worker to load the state
     * August 3rd 2022
     * William Doyle
     */
    function loadWorkerState() {
        w.current?.postMessage(JSON.stringify({
            header: {
                type: 'set internal state',
            },
            state: w_internal_state.current
        }))
    }

    /**
     * resumeWork
     * August 3rd 2022
     * William Doyle
     */
    function resumeWork() {
        switch (workState) {
            case WorkingState.Idle:
            case WorkingState.Warmup:
                LogEvent('solve_btn_pressed')
                init_worker()
                break;
            case WorkingState.Paused:
                init_worker()
                loadWorkerState()
                break;
            case WorkingState.Working:
                Dev.log(`currently working already`)
                break;
        }
        setWorkState(WorkingState.Working)
    }

    React.useEffect(() => {
        const to_show = (() => {
            if (elId > lookup.length - 1)
                return null
            return lookup[elId]
        })()
        setChallengeDataToShow(to_show)
    }, [elId, lookup])

    React.useEffect(() => {
        /*
          When the challenge data changes, we need to update the balance.                                               
          William Doyle
          July 5th 2022
        */
        if (challengeDataToShow === null)
            return

        (async () => {
            const formatedKey = `0x04${challengeDataToShow.pubkey.substring(2)}`
            if (formatedKey.length !== __expected_pub_key_length__)
                return
            const address: string = ethers.utils.computeAddress(formatedKey)
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const _balance = await provider.getBalance(address);
            setBalance(ethers.utils.formatEther(_balance))
        })()
    }, [challengeDataToShow])

    if ((challengeDataToShow === null) || (challengeDataToShow.pubkey === "Loading..."))
        return <ChallengePreviewProxy />

    return <div className="single-challenge" >
        <div
            className="svg-wrapper"
            onClick={() => navigator.clipboard.writeText(JSON.stringify(challengeDataToShow, null, 2)).then(() => alert(`copied challenge details to clipboard`))}>
            <RenderSvg
                x={ec.keyFromPublic(eth_pub_to_std_pub(challengeDataToShow.pubkey), 'hex').getPublic().getX().toString(2).padStart(256, '0')}
                y={ec.keyFromPublic(eth_pub_to_std_pub(challengeDataToShow.pubkey), 'hex').getPublic().getY().toString(2).padStart(256, '0')}
                clue={clue}
                scale={calculateScale()}
            />
        </div>
        <div>
            {(() => {
                const formatedKey = `0x04${challengeDataToShow.pubkey.substring(2)}`
                const address: string = ethers.utils.computeAddress(formatedKey)
                return <a href={`${cd?.blockchain?.explorerBaseUrl}${address}`} target="_blank" rel="noopener noreferrer">{address}</a>
            })()}
        </div>
        <span className="small-title">
            <strong>
                {`Challenge ${challengeDataToShow.position} `}
            </strong>
        </span>
        {`${(() => {
            if (elId === 0)
                return '(click image to copy details)'
            return ''
        })()}`}
        <br />
        {`Difficulty ${count_unknown_digit(challengeDataToShow.hint) * 4} bits`}
        <br />
        {`Sponsored reward ${currency_symbol()} ${balance} `}
        {
            (() => {

                if (isAlreadySolved(challengeDataToShow)) {
                    return <div className="challenge-action-buttons">
                        {/* <button */}
                        <div
                            className="btn-type-2"
                            id="widebtn"
                            // onClick={() => {
                            //     // LogEvent('sponsor_btn_pressed')
                            //     // dispatch(sponsorModalState.focusOnChallenge(challengeDataToShow))
                            //     // dispatch(sponsorModalState.open())
                            // }}
                        >

                            <a href={`https://opensea.io/collection/proof-of-quantum-certificate`} target="_blank" rel="noopener noreferrer">
                                <div className="row">
                                    {/* <AttachMoneyIcon /> */}
                                    <strong>Buy the associated NFT</strong>
                                    {/* <AttachMoneyIcon /> */}
                                </div>
                            </a>
                            {/* </button> */}

                        </div>
                    </div>
                }

                return <div className="challenge-action-buttons">
                    <button
                        disabled={isAlreadySolved(challengeDataToShow)}
                        className="btn-type-2"
                        id="widebtn"
                        onClick={workState === WorkingState.Working ? pauseWork : resumeWork}
                    >
                        <div className="row">
                            <SearchIcon />
                            <strong>{workState === WorkingState.Working ? 'STOP' : 'SOLVE IN BROWSER'}</strong>
                            <SearchIcon />
                        </div>
                    </button>
                    <>
                        {/* {isAlreadySolved(challengeDataToShow) ? <div className="already-solved">Already solved</div> : null} */}
                    </>
                    <button
                        disabled={isAlreadySolved(challengeDataToShow)}
                        className="btn-type-2"
                        id="widebtn"
                        onClick={() => {
                            LogEvent('sponsor_btn_pressed')
                            dispatch(sponsorModalState.focusOnChallenge(challengeDataToShow))
                            dispatch(sponsorModalState.open())
                        }}
                    >
                        <div className="row">
                            <AttachMoneyIcon />
                            <strong>SPONSOR THIS CHALLENGE</strong>
                            <AttachMoneyIcon />
                        </div>
                    </button>


                </div>
            })()
        }
    </div >
}
