import React from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux'
import { setContractData } from './redux/slices/contractDataSlice'
import 'react-dropdown/style.css';
import { ethers } from 'ethers';
import { Button } from '@material-ui/core';
import _contractOptions from './_contractOptions';
import ContractData from './classes/ContractData';
import ContractDetailsRecord from './classes/ContractDetailsRecord';

// IMPORT COMPONENTS
import Warning from './components/Warning/Warning';
import Maybe from './components/Maybe/Maybe';
import ProcessSolution from './components/ProcessSolution/ProcessSolution';
import DirectToMarket from './components/DirectToMarket/DirectToMarket';
import PleaseInstallMetamask from './components/PleaseInstallMetamask/PleaseInstallMetamask';
import Title from './components/Title/Title';
import QuickFill from './components/tools/QuickFill';
import Scoreboard from './components/Scoreboard/Scoreboard';
import ContractPanel from './components/ContractPanel';
import ListOfChallenges from './components/ListOfChallenges/ListOfChallenges';
import ChallengeModal from './components/ChallengeModal/ChallengeModal';
import SponsorModal from './components/SponsorModal/SponsorModal';
import SocialMediaLinks from './components/SocialMediaLinks';
import LargeChallengeModal from './components/LargeChallengeModal/LargeChallengeModal';
import TokenView from './components/TokenView/TokenView';

// IMPORT FUNCTIONS
import currentAuthUser from './functions/currentAuthUser';
import ensureHasMetamask from './functions/ensureHasMetamask';
import LogEvent from './functions/LogEvent';

// IMPORT HOOKS
import useMaintainChain from './hooks/useMaintainChain';

function useOnClickOutside(ref: any, callback: () => void) {
  React.useEffect(() => {
    function onClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target))
        callback()
    }
    document.addEventListener('mousedown', onClickOutside)
    return () => document.removeEventListener('mousedown', onClickOutside)
  }, [ref, callback])
}



function App() {

  const [showCasamaMessage, setShowCasamaMessage] = React.useState<boolean>(true)

  function CasamaMessageOverlay() {
    const wrapperRef = React.useRef<HTMLDivElement | null>(null)
    useOnClickOutside(wrapperRef, () => setShowCasamaMessage(false))

    if (!showCasamaMessage) return null

    return <div className='casama-shadow'>
      <div ref={wrapperRef} className="casama-overlay" >
        <h2>Welcome Casama User!</h2>
        <h3>Explanation</h3>
        <p>
          You may know that quantum computers threaten the security of the blockchain. This is <strong>because Quantum Computers can be used calculate a private key from a public key </strong>, enabling an adversary to steal your funds.
          To <strong>model the progress of quantum computers </strong> we have built an incentivized challenge system, a game with prizes, <strong>using purposefully weakened private keys.</strong> The game is simple, and is
          played using your computer, the easiest way to get started is in your browser. But because players with stronger computers will be able to solve the challenges faster, we offer a way to export all the data needed
          to solve these challenges offline. This is how players equipped with quantum computers will be able to play. The easiest of the challenges can be solved here in the browser, more challenging ones will be solvable offline.
          The final challenges will not be solved until the advent of a much more capable quantum computer.
          <br />
          <strong>The prizes are NFTs which can be sold on OpenSea.</strong>
        </p>
        <h3>Your Job </h3>
        <p>
          Your job is to play this game in your browser. Solve two challenges and collect the rewards for both. View the Reward Certificate NFT on OpenSea and consider listing it for sale. <strong>You need to be connected to the Polygon blockchain.</strong>
        </p>
        <div className='red-text'>
          <h3>
            <strong>Important: Refresh browser after connecting to metamask</strong>
          </h3>
        </div>


      </div>
    </div>

  }


  (window as any).ethereum.on('chainChanged', () => document.location.reload())


  const dispatch = useDispatch()
  const cd = useSelector((state: any) => state.contractData.contractData)

  const [showTools, setShowTools] = React.useState<Boolean>(false)
  const [userAddress, setUserAddress] = React.useState<string>('')
  const [hasMetamask, setHasMetamask] = React.useState<Boolean>(true)
  const [showContractPanel, setShowContractPanel] = React.useState<Boolean>(false)

  const hasLoaded = React.useRef(false)
  React.useEffect(() => {
    if (!hasLoaded.current)
      LogEvent('app_load')
    hasLoaded.current = true
  }, [])

  /* 
    source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
    August 8th 2022
    William Doyle
  */
  const [showButton, setShowButton] = React.useState<boolean>(false); // back to top button

  const queryParams = React.useMemo(() => new URLSearchParams(window.location.search), []);

  useMaintainChain(parseInt(queryParams.get('chainid') ?? '137').toString(16))


  React.useEffect(() => {
    /* 
      source: https://www.kindacode.com/article/how-to-create-a-scroll-to-top-button-in-react/ 
      August 8th 2022
      William Doyle
    */
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300)
        setShowButton(true)
      else
        setShowButton(false)
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    })
  }

  React.useEffect(() => {
    ensureHasMetamask(() => setHasMetamask(false));
    (async () => setUserAddress(await currentAuthUser()))();

    const co_vals: ContractData[] = _contractOptions.map((c: ContractDetailsRecord) => c.value)
    const target_index: number = co_vals.findIndex((c: ContractData) => {
      const chainId: string | null = queryParams.get('chainid')
      // if no chainid is specified, redirect to __current_url__?chainid=137
      if (chainId === null) {
        const new_url = `${window.location.href}?chainid=137`
        window.location.href = new_url
      }

      return c.blockchain?.chainid.toString() === chainId
    })

    console.log('target_index', target_index)

    const contractDataToUse: ContractData = co_vals[target_index]
    dispatch(setContractData(contractDataToUse));

  }, [dispatch, queryParams])

  // IF NO METAMASK IS INSTALLED, SHOW A MESSAGE
  if (!hasMetamask)
    <PleaseInstallMetamask />



  return (
    <div className="App">
      <CasamaMessageOverlay />
      <ProcessSolution />


      <header className="App-header">

        <Maybe IF={cd?.blockchain?.isTestNet ?? false}
          THEN={<Warning />}
          OTHERWISE={<></>} />

        <div className="fancy-title-wrap">
          <div className="wide-spacer" />
          <div className="fancy-title">
            <Title text='Proof-of-quantum™' level={1} />
          </div>
          <div className="wide-spacer" />
        </div>
        <SocialMediaLinks position='top' />
      </header>
      <div className="Main">
        <LargeChallengeModal />
        <ChallengeModal />
        <SponsorModal />
        {/* <Title text='When Can A Quantum Computer Steal Your Money? When There Is A Data Point At 256 On The Graph Below' level={3} />
        <Scoreboard /> */}
        <div className="challenges-section">
          <Title text='Challenges' level={2} />
          <ListOfChallenges />
          {
            (() => {
              if (userAddress === '0x94D4Da7bDe814ae3B7a3D25A108391018e1e495E')
                return <Button variant="outlined" onClick={() => setShowTools(!showTools)}>
                  <div className="Button-Label">
                    Show/Hide Tools
                  </div>
                </Button>
            })()
          }
        </div>
        {
          (() => {
            if (showTools)
              return <div className="Additional-Tools">
                <QuickFill />
              </div>
          })()
        }
        <div>
          <button className="btn-type-2" onClick={() => setShowContractPanel(!showContractPanel)}>
            Show/Hide Contract Panel
          </button>
        </div>
        {
          (() => {
            if ((showContractPanel) && (cd))
              return <ContractPanel contract={new ethers.Contract(cd.address, cd.abi)} />
          })()
        }
      </div>
<Title text='When Can A Quantum Computer Steal Your Money? When There Is A Data Point At 256 On The Graph Below' level={3} />
        <Scoreboard />
      <DirectToMarket />
      <TokenView address={userAddress} />
      <div className="footer">
        <div className='footer-element'>
          <SocialMediaLinks position='bottom' bottom={true} />
        </div>
      </div >
      <Maybe
        IF={showButton}
        THEN={
          <button onClick={scrollToTop} className="back-to-top">
            &#8679;
          </button>
        }
        OTHERWISE={<></>}
      />
    </div >
  );
}

export default App;

// TOP        --> Title + Link Bar
// CONTENT 1  --> Scoreboard
// CONTENT 2  --> List of Challenges
// CONTENT 3  --> Direct To Market
// BOTTOM     --> Footer (Link Bar Again)