import { ethers } from 'ethers'
import ProviderSingleton from '../classes/ProviderSingleton';

export default async function callContractFunction(contract: ethers.Contract, functionName: string, callData: any, messageValue: number | ethers.BigNumber = 0) {
    // const provider = new ethers.providers.Web3Provider((window as any).ethereum);
    const provider = await ProviderSingleton.getInstance()
    // await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner()
    const contractWithSigner = contract.connect(signer);

    const values = [...Object.values(callData)];

    try {
        console.log('calling contract function [funcationName, values, messageValue]', functionName, values, messageValue)
        const _result = await contractWithSigner[functionName](...values, {value: messageValue})
        return _result
    }
    catch (e) {
        // alert(JSON.stringify(e))
        console.log(e)
        console.log(`values `, values)
        return null
    }
}
