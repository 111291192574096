
import color_sets from './color_sets'
import { check_256_bit , check_clue} from './check_256_bit'

export default function art4(x: string, y: string, clue: string, scale: number = 2): string {
    // 1. checks
    check_256_bit(x)
    check_256_bit(y)
    check_clue(clue)

    // 2. concat
    const blob = x + y + clue

    // 3. convert string to array
    const blob_array: boolean[] = blob.split('').map((element: string) => element === '1')

    // 4. convert array to matrix (16x48)
    const matrix: boolean[][] = []
    for (let i = 0; i < blob_array.length; i += 16)
        matrix.push(blob_array.slice(i, i + 16))

    // 5. convert matrix to svg
    let svg: string = ''

    const gen_color = (x: number, y: number, matrix: boolean[][]): string => {
        if (y > matrix.length - 1)
            throw new Error(`y is out of bounds`)
        if (x > matrix[y].length - 1)
            throw new Error(`x is out of bounds`)

        const state: 'zero' | 'one' = matrix[y][x] ? 'one' : 'zero'
        const color_set = (() => {
            if (y >= (16 * 2))
                return color_sets[0]
            if (y >= 16)
                return color_sets[1]
            return color_sets[2]
        })()
        return color_set[state]
    }

    // 6. determine the number of zeros at the end of the clue
    const n = scale
    for (let index_x = 0; index_x < 16; index_x++)
        for (let index_y = 0; index_y < 48; index_y++) {
            svg = svg + `${'\t'.repeat(index_x)}
            <rect 
                x="${index_x * n * 3}" 
                y="${index_y * n}" 
                width="${n * 3}" 
                height="${n}" 
                stroke="white" 
                fill="${gen_color(index_x, index_y, matrix)}" 
                stroke-width="1"
            />\n`
        }


    // 7. turn the redacted bits of the clue white in the svg
    // 8. return svg
    return `<svg xmlns="http://www.w3.org/2000/svg" width="${16 * n * 3}" height="${16 * 3 * n}">${svg}</svg>`
}