/*
    ObjectToTable
    William Doyle
    June 6th 2022
*/
export default function ObjectToTable(params: any) {
    if (typeof params.obj === 'string')
        return <div className="string-display">{params.obj}</div>
    if (!params.obj)
        return <div>null</div>

    return <table >
        <tbody>
            {
                Object.keys(params.obj).map((key, index) => {
                    return <tr key={index}>
                        <td>{key}</td>
                        {
                            (() => {
                                const el = params.obj[key]
                                if (!el)
                                    return <td>NOTHING</td>
                                const type = typeof el

                                switch (type) {
                                    case 'string':
                                        if (el.length > 128)
                                            return <td>{`${el.substring(0, 128)}${'...'}`}</td>
                                        return <td>{el}</td>
                                    case 'number':
                                    case 'boolean':
                                    case 'undefined':
                                        return <td>{el}</td>
                                    default:
                                        if (el?.type === 'BigNumber' || el?.hasOwnProperty('_isBigNumber')) {
                                            return <td>{(() => {
                                                if (!el)
                                                    return "NOTHING"
                                                const n_val = el?.toNumber();
                                                if (n_val > 1600000000 && n_val < ((new Date()).getTime()) / 1000)
                                                    return (new Date(n_val * 1000)).toLocaleString()
                                                return el?.toString()
                                            })()}</td>
                                        }
                                        return <td><ObjectToTable obj={params.obj[key]} trim={params.trim} /></td>
                                }
                            })()
                        }
                    </tr>
                })
            }
        </tbody>
    </table>
}