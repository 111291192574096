import ScoreboardEntry from './ScoreboardEntry'
import { ethers } from 'ethers'
import calc_difficulty from '../../functions/calc_difficulty'
import count_unknown_digit from '../../functions/count_unknown_digit';

export default function processDataPoints(d: any[] | null): ScoreboardEntry[] {
    if (d === null)
        return []

    const rval: ScoreboardEntry[] = []

    function timeSinceLastMint(ts: number): number {
        if (rval.length === 0)
            return 0

        const lastEntry = rval[rval.length - 1]
        return ts - lastEntry.ts_submission
    }

    d.forEach((entry: any) => {
        // rval.push({
        //     ts_submission: ethers.BigNumber.from(entry[1][3]).toNumber(),
        //     ts_puzzle_posted: ethers.BigNumber.from(entry[1][2]).toNumber(),
        //     time_to_solve: ethers.BigNumber.from(entry[1][3]).toNumber() - ethers.BigNumber.from(entry[1][2]).toNumber(),
        //     difficulty: calc_difficulty(entry[0][1][1]),
        //     difficulty_a: calc_difficulty(entry[0][1][1]) * 1000,
        //     pubkey: entry[0][1][0],
        //     challenge_id: entry[0][0],
        //     solvedBy: entry[3],
        //     tokenId: ethers.BigNumber.from(entry.tokenId).toString(),
        //     asset_url: `pauli.group/chain_id/contractaddress/${entry.tokenId}`,
        //     time_since_last_mint: timeSinceLastMint(ethers.BigNumber.from(entry[1][3]).toNumber()),
        //     unknown_bits: count_unknown_digit(entry.challenge.currentPuzzle.knownBytes) * 4,
        // })
        const temp: ScoreboardEntry = {
            ts_submission: ethers.BigNumber.from(entry[1][3]).toNumber(),
            ts_puzzle_posted: ethers.BigNumber.from(entry[1][2]).toNumber(),
            time_to_solve: ethers.BigNumber.from(entry[1][3]).toNumber() - ethers.BigNumber.from(entry[1][2]).toNumber(),
            difficulty: calc_difficulty(entry[0][1][1]),
            difficulty_a: calc_difficulty(entry[0][1][1]) * 1000,
            pubkey: entry[0][1][0],
            challenge_id: entry[0][0],
            solvedBy: entry[3],
            tokenId: ethers.BigNumber.from(entry.tokenId).toString(),
            asset_url: `pauli.group/chain_id/contractaddress/${entry.tokenId}`,
            time_since_last_mint: timeSinceLastMint(ethers.BigNumber.from(entry[1][3]).toNumber()),
            unknown_bits: count_unknown_digit(entry.challenge.currentPuzzle.knownBytes) * 4,
            is_mint_event: true,
        }
        rval.push(temp)

    })

    return rval
}