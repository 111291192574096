
/*
    Counts the number of zeros at the end of a string
    William Doyle
*/
const count_unknown_digit = (clue: string) => {
    // return the number of trailing zeros
    let i = clue.length - 1
    let tally = 0
    while (clue[i] === '0') {
        tally += 1
        i -= 1
    }
    return tally
}

export default count_unknown_digit
