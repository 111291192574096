
import { createSlice } from '@reduxjs/toolkit'

const dataPointsSlice = createSlice({
    name: 'dataPointsSlice',
    initialState: {
        dataPoints : null,
        loaded: false,
    },
    reducers: {
        setDataPoints: (state:any, action:any) => {
            state.dataPoints = action.payload
        },
        setLoaded: (state:any, action:any) => {
            state.loaded = action.payload
        }
    }
})
export default dataPointsSlice
export const { setDataPoints, setLoaded } = dataPointsSlice.actions