import React from 'react';
import SVG from 'react-inlinesvg';
import { safe_check_256_bit , safe_check_clue} from './check_256_bit'
import loading_svg from './loading_svg';
import art4 from './art4'

type Props = {
    x: string
    y: string
    clue: string
    scale: number
}

const default_x: string = `01${'0'.repeat(251)}110`
const default_y: string = `011${'0'.repeat(249)}1110`
const default_clue: string = `0111${'0'.repeat(247)}11110`
const default_scale = 2

export default function RenderSvg({ x = default_x, y = default_y, clue = default_clue, scale = default_scale }: Props) {
    const [svgText, setSvgText] = React.useState<string>(loading_svg)

    React.useEffect(() => {
        // if (!(safe_check_256_bit(x) && safe_check_256_bit(y) && safe_check_256_bit(clue))) 
        // if (!(safe_check_256_bit(x) && safe_check_256_bit(y) && check_clue(clue))) 
        if (!(safe_check_256_bit(x) && safe_check_256_bit(y) && safe_check_clue(clue))) 
        {
            setSvgText(loading_svg)
            return
        }
        const img: string = art4(x, y, clue, scale)
        // const img: string = art4(default_x, default_y, default_clue, scale)
        setSvgText(img)
    }, [x, y, clue, scale])

    return <SVG src={svgText}/>
} 
