import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Popover } from '@material-ui/core';
import FunctionList from './FunctionList';
import { setCallData, setMessageValue } from '../redux/slices/callDataSlice';
import LooseObject from '../classes/LooseObject';
import GenerateSolutionRedeemer from './tools/GenerateSolutionRedeemer';
import ObjectToTable from './ObjectToTable';
import callContractFunction from '../functions/callContractFunction';
import ListOfRandomNames from '../constants/ListOfRandomNames';

export default function ContractPanel(props: any) {
    const dispatch = useDispatch()
    const callData = useSelector((state: any) => state.callData.callData)
    const messageValue = useSelector((state: any) => state.callData.messageValue)

    // @target and @generatedArgument --> allow QuickFill to modify this
    const target = useSelector((state: any) => state.generatedInputValue.target)
    const generatedArgument = useSelector((state: any) => state.generatedInputValue.value)

    const past_values = React.useRef([] as any[])
    const past_targets = React.useRef([] as any[])

    const [result, setResult] = React.useState({} as LooseObject);
    const [selectedFunction, setSelectedFunction] = React.useState(null as any)
    const [open, setOpen] = React.useState(false);
    const [messageValueBuffer, setMessageValueBuffer] = React.useState<string>('0');
    const anchorEl = React.useRef(null);

    function functionListCallback(input: any) {
        const f = props.contract.interface.fragments.filter((frag: any) => frag.type === 'function').find((el: any) => el.name === input)
        setSelectedFunction(f)
    }

    React.useEffect(() => {
        if (!selectedFunction)
            return

        const argNames = selectedFunction.inputs.map((input: any) => input.name)
        const obj: any = {}
        for (const argName of argNames) {
            obj[argName] = undefined
            if (past_targets.current.includes(argName))
                obj[argName] = past_values.current[past_targets.current.indexOf(argName)]

            if ((selectedFunction?.name === target.function_name) && (argName === target.target_param)) {
                obj[argName] = generatedArgument
                past_values.current.push(generatedArgument)
                past_targets.current.push(argName)
            }
        }

        if (selectedFunction.name === 'mint')
            obj['playerName'] = ListOfRandomNames[Math.floor(Math.random() * ListOfRandomNames.length)]

        dispatch(setCallData(obj))
    }, [selectedFunction, generatedArgument, dispatch, target])

    return <div className="contract-panel" >
        <div className="contract-panel-function-list" >
            {/* select function */}
            <FunctionList
                functions={props.contract.interface.fragments.filter((frag: any) => frag.type === 'function').reverse()}
                onSelect={functionListCallback}
            />
        </div>
        <div className="contract-panel-body" >
            <div className="contract-panel-function-inputs" >
                <h1>
                    {selectedFunction ? selectedFunction.name : "Select a function"}
                </h1>
                <div className="row" >
                    <div className="argument-table">
                        <table border={1}>
                            <thead>
                                <tr>
                                    <th>Argument Name</th>
                                    <th>Argument Type</th>
                                    <th>Argument Value</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                (() => {
                                    if (!selectedFunction)
                                        return <tr><td>please select a function</td></tr>
                                    if (callData === null)
                                        return <tr><td>please wait</td></tr>
                                    return selectedFunction.inputs.map((parameter: any) => {
                                        return <tr key={parameter.name}>
                                            <td>{parameter.name}</td>
                                            <td>{parameter.type}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    value={callData[parameter.name] ?? ""}
                                                    onChange={e => {
                                                        const argName: string = parameter.name;
                                                        const sol: LooseObject = {
                                                            ...(callData),
                                                        }
                                                        sol[argName] = e.target.value
                                                        dispatch(setCallData(sol))
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    })
                                })()
                            }
                            {
                                /// if selected function is 'sponsor' include an additional input for the amount to sponsor
                                (() => {
                                    if (selectedFunction?.name !== 'sponsor')
                                        return <></>
                                    return <tr key={'sponsor_amount'}>
                                        <td>Amount To Give</td>
                                        <td>uint256</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={messageValueBuffer}
                                                onChange={e => setMessageValueBuffer(e.target.value)}
                                            />
                                            <button onClick={() => {
                                                const n: number = parseInt(messageValueBuffer)
                                                dispatch(setMessageValue(n))
                                            }}>set</button>
                                            <label>{messageValue}</label>
                                        </td>
                                    </tr>
                                })()
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className="wide-spacer" />
                    <button className="btn-type-2" onClick={async () => { setResult(await callContractFunction(props.contract, selectedFunction.name, callData, messageValue)) }}>
                        <strong> {`Call ${selectedFunction ? selectedFunction.name : "selected function"}`} </strong>
                    </button>
                    <div className="wide-spacer" />
                </div>
                <br />
                {
                    (() => {
                        if (selectedFunction?.name !== 'mint')
                            return <></>

                        return <div className='row'>
                            <div className="wide-spacer" />
                            <button
                                ref={anchorEl}
                                onClick={() => setOpen(true)}
                                className="btn-type-2"
                            >
                                <strong>Make Ticket Sig</strong>
                            </button>
                            <Popover
                                open={open}
                                anchorEl={anchorEl.current}
                                onClose={() => setOpen(false)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <GenerateSolutionRedeemer />
                            </Popover>
                            <div className="wide-spacer" />
                        </div>
                    })()
                }
            </div>
            <div className="contract-panel-function-outputs" >
                {/* view results */}
                <h1>
                    Results
                </h1>
                <ObjectToTable obj={result} trim={true} />
            </div>
        </div>
    </div>
}