import { createSlice } from '@reduxjs/toolkit'
import DataRow from '../../classes/DataRow'

type Default = {
    isOpen: boolean,
    focusedChallenge: DataRow | null,
}

const initialState: Default = {
    isOpen: false,
    focusedChallenge: null,
}

const challengeModalSlice = createSlice({
    name: 'challengeModalSlice',
    initialState: initialState,
    reducers: {
        open: (state:any) => {
            state.isOpen = true
        },
        close: (state:any) => {
            state.isOpen = false
        },
        focusOnChallenge: (state:any, action:any) => {
            state.focusedChallenge = action.payload
        }

    }
})
export default challengeModalSlice
export const { open, close, focusOnChallenge } = challengeModalSlice.actions