import React from "react"
import useSmartContract from "../../hooks/useSmartContract";
import calc_difficulty from "../../functions/calc_difficulty";
import { useSelector, useDispatch } from 'react-redux'
import DataTable from "../../classes/DataTable";
import { pubkey_of_puzzle_to_solver_address, pubkey_of_puzzle_to_token_url } from "./functions";
import { initialDataTable } from "./constants";
import { setLookup } from "../../redux/slices/challengeDisplaySlice";
import ChallengeNavigator from "./ChallengeNavigator";
import ChallengePreview from "../ChallengePreview/ChallengePreview";
import Dropdown from 'react-dropdown';
import DataRow from "../../classes/DataRow";
import { ethers } from "ethers";
import BN from "bn.js";

enum WaysToSort {
    Difficulty = "difficulty",
    Position = "position",
    SponsorShips = "sponsorships",
}

export default function ListOfChallenges() {
    const rawData = useSmartContract("getAllChallenges")
    const [data, setData] = React.useState<DataTable>(initialDataTable)
    const [displayRange, setDisplayRange] = React.useState<[number, number, number]>([0, 8, 0])
    const [hideSolved, setHideSolved] = React.useState<boolean>(true)
    const [sortBy, setSortBy] = React.useState<WaysToSort>(WaysToSort.Position)

    const dataPoints = useSelector((state: any) => state.dataPoints.dataPoints)
    const dispatch = useDispatch()

    React.useEffect(() => {
        function _sort_(a: any, b: any){
            if (sortBy === WaysToSort.Difficulty)
                return a.difficulty - b.difficulty
            if (sortBy === WaysToSort.Position)
                return parseInt(a.position) - parseInt(b.position)
            if (sortBy === WaysToSort.SponsorShips)
            {
                const A = new BN(a.sponsorships)
                const B = new BN(b.sponsorships)

                if (A.gt(B))
                    return -1
                if (A.lt(B))
                    return 1
                return 0
            }
            return 0
        }

        (async () => {
// console.log(`rawData`, rawData)

            if (rawData === null)
                return

            const pub2address = (pubkey: string) => ethers.utils.computeAddress(`0x04${pubkey.substring(2)}`)
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);

            // process the raw data into a format that can be used by the table
            const processedData: DataTable = (await Promise.all(rawData.map(async (row: any, index: number) => {
                const rval: DataRow = ({
                    difficulty: calc_difficulty(row.currentPuzzle.knownBytes), // TODO: calculate with the same function used in the scoreboard
                    pubkey: row.currentPuzzle.publicKey,
                    hint: row.currentPuzzle.knownBytes,
                    reward: pubkey_of_puzzle_to_token_url(row.currentPuzzle.publicKey, dataPoints),
                    challenger: pubkey_of_puzzle_to_solver_address(row.currentPuzzle.publicKey, dataPoints),
                    position: row.position.toString(),
                    sponsorships: (await provider.getBalance(pub2address(row.currentPuzzle.publicKey))).toString(),
                })

                if (!hideSolved)
                    return rval

                if (rval.challenger !== 'NA')
                    return null

                return rval
            }))).filter((el: any | null) => el !== null).sort(_sort_)

            // console.log(`processedData`, processedData)

            setData(processedData)
        })();
    }, [rawData, dataPoints, hideSolved, sortBy,])

    // when displayRange or data changes, update the display
    React.useEffect(() => {
        if (data === null)
            return

        const _display = data.slice(displayRange[0], displayRange[1])
        dispatch(setLookup(_display))
    }, [displayRange, data, dispatch])

    return (
        <div className="challenges-shop-layout-view-parent">
            <ChallengeNavigator setDisplayRange={setDisplayRange} displayRange={displayRange} data={data} />
            <div className="challenges-table-row">
                <ChallengePreview elId={0} data={data} />
                <ChallengePreview elId={1} data={data} />
                <ChallengePreview elId={2} data={data} />
                <ChallengePreview elId={3} data={data} />
                <ChallengePreview elId={4} data={data} />
                <ChallengePreview elId={5} data={data} />
                <ChallengePreview elId={6} data={data} />
                <ChallengePreview elId={7} data={data} />
            </div>
            <ChallengeNavigator setDisplayRange={setDisplayRange} displayRange={displayRange} data={data} />
            <div className="challenges-display-control-area">

                <div className="challenges-display-control">
                    <Dropdown options={['Hide Solved', 'Show Solved']} onChange={(e: any) => {
                        if (e.value === 'Hide Solved')
                            setHideSolved(true)
                        if (e.value === 'Show Solved')
                            setHideSolved(false)
                    }} value={'Hide Solved'} placeholder="Show Or Hide solved" />
                </div>

                <div className="challenges-display-control">
                    <Dropdown options={[{
                        value: WaysToSort.Difficulty,
                        label: 'Sort By Difficulty',
                    }, {
                        value: WaysToSort.Position,
                        label: 'Sort By Position'
                    },
                    {
                        value: WaysToSort.SponsorShips,
                        label: 'Sort By Sponsor Ships'
                    }]} onChange={(e: any) => setSortBy(e.value)} value={''} placeholder="Sort By" />
                </div>
            </div>
        </div>
    )
}