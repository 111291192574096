import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { close } from '../../redux/slices/sponsorModalSlice'
import CloseIcon from '@material-ui/icons/Close';
import callContractFunction from '../../functions/callContractFunction';
import { ethers } from 'ethers';
import currency_symbol from '../../functions/currency_symbol';


export default function SponsorModal() {

    const dispatch = useDispatch()
    const isOpen = useSelector((state: any) => state.sponsorModal.isOpen)
    const cd = useSelector((state: any) => state.contractData.contractData)
    const challengeDetails = useSelector((state: any) => state.sponsorModal.focusedChallenge)

    const [sponsorName, setSponsorName] = React.useState<string>("")
    const [sponsorMessage, setSponsorMessage] = React.useState<string>("")
    const [sponsorAmount, setSponsorAmount] = React.useState<string>("")

    const [haveConsent, setHaveConsent] = React.useState<boolean>(false)

    /*
        Use the input values (stored in local state) to construct a call to 
        the sponsor function.
        William Doyle
        July 5th 2022
    */
    async function sponsor() {
        if (sponsorName === "" || sponsorMessage === "" || sponsorAmount === "")
            return
        const contract = new ethers.Contract(cd.address, cd.abi)
        // const i_amount : number = parseInt(sponsorAmount)
        const bn_amount: ethers.BigNumber = ethers.utils.parseEther(sponsorAmount)
        await callContractFunction(contract, 'sponsor', [challengeDetails.position, sponsorName, sponsorMessage], bn_amount)
        dispatch(close())
    }

    if (isOpen)
        return <div className="sponsor-modal">
            <div id="top" className="challenge-modal-child">
                <div className="wide-spacer" >
                    <h2>
                        {`Sponsor Challenge ${challengeDetails?.position ?? 'Unknown'}`}
                    </h2>
                </div>
                <CloseIcon onClick={() => dispatch(close())} />
            </div>
            {/* <table border={1}> */}
            <table>
                {/* <tr>
                    <td>position</td>
                    <td>{challengeDetails?.position ?? ''}</td>
                </tr> */}
                <tbody>
                    <tr>
                        <td>Sponsor Name</td>
                        <td>
                            <input
                                type="text"
                                value={sponsorName}
                                onChange={e => setSponsorName(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Sponsor Message</td>
                        <td>
                            <input
                                type="text"
                                value={sponsorMessage}
                                onChange={e => setSponsorMessage(e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Sponsor Amount</td>
                        <td>
                            <input
                                type="text"
                                value={sponsorAmount}
                                onChange={e => setSponsorAmount(e.target.value)}
                                placeholder={`3 ${currency_symbol()} minimum`}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input 
                                type="checkbox"
                                 id="sponsor-consent-check"
                                 onClick={() => setHaveConsent(!haveConsent)}
                                 checked={haveConsent}
                                  />
                        </td>
                        <td>
                            <label htmlFor="sponsor-consent-check">
                                {/* {`I have read the sponsorship source code and understand <get from contract>% of my sponsorship will go to Pauli Group`} */}
                                {`I understand 20% of my sponsorship will go to Pauli Group to fund the development of this and future projects`}
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button 
                onClick={sponsor}
                disabled={!haveConsent}
                >
                Sponsor
            </button>
        </div>
    return <></>
}