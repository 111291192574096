import { useSelector } from 'react-redux'
import "./DirectToMarket.css"


/**
 *  DirectToMarket
 *  William Doyle
 *  August 9th 2022 
 */
export default function DirectToMarket() {
    const cd = useSelector((state: any) => state.contractData.contractData)

    return <a href={cd?.marketplaceURL} target="_blank" rel="noopener noreferrer">
        <div className="direct-to-market-section">
            <div className="market-title">
                <h2 id='link-hover-style-exception'>
                    {`BUY AND SELL ON ${cd?.marketShortName.toString().toUpperCase()}`}
                </h2>
            </div>
            <div className="placeholder" />
        </div>
    </a>
}