import React from 'react'
import { ethers } from 'ethers'
// import { HelpECDSA_Sig } from '../Contract';
import HelpECDSA_Sig from '../../classes/HelpECDSA_Sig';
import LooseObject from '../../classes/LooseObject';
import { setGeneratedInputValue, setTargetParam } from '../../redux/slices/generatedInputValueSlice';
import { useDispatch } from 'react-redux'
import currentAuthUser from '../../functions/currentAuthUser';

export async function newSignedHash2(message: string, wallet: ethers.Wallet): Promise<HelpECDSA_Sig> {
    const mHash: string = ethers.utils.solidityKeccak256(['bytes'], [message]);
    const flatSig: string = await wallet.signMessage(ethers.utils.arrayify(mHash));
    const sig: ethers.Signature = ethers.utils.splitSignature(flatSig);

    return {
        v: sig.v,
        r: sig.r,
        s: sig.s,
        messageHash: mHash,
        flatSig: flatSig
    }
};



export default function GenerateSolutionRedeemer(props: any) {

    const dispatch = useDispatch()
    const [solutionPrivateKey, setSolutionPrivateKey] = React.useState('')

    const temp: LooseObject = {}
    const [proof, setProof] = React.useState(temp)

    async function calculateSolution() {
        // get current address 
        const user: string = await currentAuthUser()
        const sig = await newSignedHash2(user, new ethers.Wallet(solutionPrivateKey))
        setProof(sig)
        console.log(`pubkey: ${`${new ethers.Wallet(solutionPrivateKey).address} `}`)
    }

    return <div className='generate-solution-sig-tool'>
        <h3>
            Generate Solution Redeemer
        </h3>
        <table>
            <tr>
                <td><label>Solution Private Key</label></td>
                <td>
                    <input
                        type="text"
                        value={solutionPrivateKey}
                        onChange={e => setSolutionPrivateKey(e.target.value)}
                    />
                </td>
            </tr>
        </table>
        <button onClick={calculateSolution}>Generate</button>
        <button onClick={async () => {
            dispatch(setTargetParam({
                function_name: 'mint',
                target_param: 'hMessage',
            }))
            dispatch(setGeneratedInputValue(proof.messageHash) as any)

            await new Promise(f => setTimeout(f, 1000));

            dispatch(setTargetParam({
                function_name: 'mint',
                target_param: 'proofSig',
            }))
            dispatch(setGeneratedInputValue(proof.flatSig) as any)
        }}>Set Proof</button>
        <tr>
            <td><label>hMessage</label></td>
            <td>{proof.messageHash}</td>
        </tr>
        <tr>
            <td><label>flatSig</label></td>
            <td>{proof.flatSig}</td>
        </tr>
    </div>
}