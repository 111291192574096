export const check_256_bit = (n: string) => {
    if (n.length !== 256)
        throw new Error(`Each input mut be an 256 binary integer as a string (${n.length})`)

    // get all unique characters in n
    const unique_chars = new Set(n.split(''))
    const unique_chars_array = Array.from(unique_chars)
    if ((unique_chars_array.length !== 2) || (!unique_chars_array.includes('0') || !unique_chars_array.includes('1')))
        throw new Error(`Expected a string of bits`)
}

export const check_clue = (n: string) => {
    // console.log(n)
    // console.log(n.length)
    if (n.length !== 256)
        throw new Error(`Each input mut be an 256 binary integer as a string (${n.length})`)
}

export const safe_check_clue = (n: string) => {
    if (n.length !== 256)
        return false
    return true
}

export const safe_check_256_bit = (n: string) => {
    if (n.length !== 256)
        return false

    // get all unique characters in n
    const unique_chars = new Set(n.split(''))
    const unique_chars_array = Array.from(unique_chars)
    if ((unique_chars_array.length !== 2) || (!unique_chars_array.includes('0') || !unique_chars_array.includes('1')))
        return false

    return true
}