import { createSlice } from '@reduxjs/toolkit'

type InitialState = {
    contractData : any,
}

const istate : InitialState = {
    contractData : null,
}

const contractDataSlice = createSlice({
    name: 'contractDataSlice',
    initialState: istate,
    reducers: {
        setContractData: (state:any, action:any) => {
            // console.log(`stub: typeof payload is "${action.payload.constructor.name}"`)
            state.contractData = action.payload
        }
    }
})
export default contractDataSlice
export const { setContractData } =contractDataSlice.actions