import React from "react"
import RenderSvg from "../RenderSvg/RenderSvg";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import calculateScale from "../../functions/calculateScale";

/**
 * ChallangePreviewProxy.tsx
 * William Doyle
 * Display a mockup of the challenge preview component.
 */
export default function ChallengePreviewProxy() {
    return <div className="single-challenge" >
        <div className="svg-wrapper">
            <RenderSvg x={`01${'0'.repeat(251)}110`} y={`011${'0'.repeat(249)}1110`} clue={`0111${'0'.repeat(247)}11110`} scale={calculateScale()} />
        </div>
        <div>
            <a href="#main-event">address</a>
        </div>
        <span className="small-title">
            <strong>
                {`Loading... `}
            </strong>
        </span>
        <br />
        {`Loading... `}
        <br />
        {`Loading... `}
        {
            (() => {
                return <div className="challenge-action-buttons">
                    <button
                        className="btn-type-2"
                        id="widebtn"
                    >
                        <div className="row">
                            <SearchIcon />
                            <strong>SOLVE</strong>
                            <SearchIcon />
                        </div>
                    </button>
                    <button
                        className="btn-type-2"
                        id="widebtn"
                    >
                        <div className="row">
                            <AttachMoneyIcon />
                            <strong>SPONSOR</strong>
                            <AttachMoneyIcon />
                        </div>
                    </button>
                </div>
            })()
        }
    </div >
}