type Params = {
    text: string
    level: number
}

export default function Title (params : Params) {
    switch (params.level) {
        case 1:
            return <h1>{params.text}</h1>
        case 2:
            return <h2>{params.text}</h2>
        case 3:
            return <h3>{params.text}</h3>
        case 4:
            return <h4>{params.text}</h4>
        case 5:
            return <h5>{params.text}</h5>
        case 6:
            return <h6>{params.text}</h6>
        default:
            return <h1>{params.text}</h1>
    }
}

