import { createSlice } from '@reduxjs/toolkit';

interface Target {
    function_name: string
    target_param: string
}

const generatedInputValueSlice = createSlice({
    name: 'generatedInputValueSlice',
    initialState: {
        value: {} as any,
        target: {} as Target,
    },
    reducers: {
        setGeneratedInputValue: (state:any, action:any) => {
            console.log(`STUB: inside setGeneratedInputValue: ${action.payload}`);
            state.value = action.payload
        },
        setTargetParam: (state:any, action:any) => {
            console.log(`STUB: inside setTargetParam: ${action.payload}`);
            state.target = action.payload
        }
    }
})

export default generatedInputValueSlice;
export const { setGeneratedInputValue, setTargetParam } = generatedInputValueSlice.actions;
