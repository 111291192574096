import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { close } from '../../redux/slices/challengeModalSlice'
import CloseIcon from '@material-ui/icons/Close';
import makeInviteText from './makeInviteText';

export default function ChallengeModal() {
    const dispatch = useDispatch()
    const isOpen = useSelector((state: any) => state.challengeModal.isOpen)
    const challengeDetails = useSelector((state: any) => state.challengeModal.focusedChallenge)
    const [inviteEmail, setInviteEmail] = React.useState<string>("")
    
    const inviteText = makeInviteText(challengeDetails)
    const subject = `Proof-Of-Quantum Challenge ${challengeDetails?.position ?? ''}`

    if (isOpen)
        return <div className="challenge-modal">
            <div id="top" className="challenge-modal-child">
                <div className="wide-spacer" >
                    <h2>
                        {`Share Challenge ${challengeDetails?.position ?? 'Unknown'}`}
                    </h2>
                </div>
                <CloseIcon onClick={() => dispatch(close())} />
            </div>
            <div id="middle" className="challenge-modal-child">
                <label>Email To Invite</label>
                <input
                    type="text"
                    value={inviteEmail}
                    onChange={e => setInviteEmail(e.target.value)}
                />
            </div>
            <div id="bottom" className="challenge-modal-child">
                <div className="wide-spacer" />
                <a href={`mailto:${inviteEmail}?subject=${subject}&body=${inviteText}`}>
                    Send Invite
                </a>
                <div className="wide-spacer" />
            </div>
        </div>
    return <></>
}