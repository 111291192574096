import DataTable from "../../classes/DataTable";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

type ChallengeNavigator_Props = {
    setDisplayRange: any,
    displayRange: [number, number, number],
    data: DataTable,
}

const mod = (mod_by: number) => (n: number) => ((n % mod_by) + mod_by) % mod_by

/*
    calc_page_number    
    Used to ensure that the display range is correct
    July 4th 2022
    William Doyle
*/
// function calc_page_number(n: number, elcount: number, elperpage: number): number {
//     return mod(Math.floor(elcount / elperpage))(n)
// }
const calc_page_number = (n: number, elcount: number, elperpage: number): number => {
    const returnvalue: number = mod(Math.floor(elcount / elperpage))(n)
    if (isNaN(returnvalue))
        return 0
    return returnvalue
}

const move_by: number = 8

export default function ChallengeNavigator({ setDisplayRange, displayRange, data }: ChallengeNavigator_Props) {
    return <div className="challenge-table-navigator">
        <div
            className="nav-btn"
            onClick={() => setDisplayRange(
                [
                    (displayRange[0] - move_by) % data.length,
                    (displayRange[1] - move_by) % data.length,
                    (displayRange[2] - 1)
                ])}>
            <NavigateBeforeIcon />
        </div>
        <div>
            <label><h2>{`${1 + calc_page_number(displayRange[2], data.length, move_by)}`} </h2></label>
        </div>
        <div
            className="nav-btn"
            onClick={() => setDisplayRange(
                [
                    (displayRange[0] + move_by) % data.length,
                    (displayRange[1] + move_by) % data.length,
                    (displayRange[2] + 1)
                ])}>
            <NavigateNextIcon />
        </div>
    </div>
}
