const ListOfRandomNames = [
    'Abigail',
    'Ada',
    'Adelaide',
    'Adele',
    'Adeline',
    'Adrian',
    'Adrienne',
    'Agatha',
    'Agnes',
    'Aida',
    'Aileen',
    'Aimee',
    'Aisha',
    'Aja',
    'Ajay',
    'Alaina',
    'Bruce',
    'Bane',
    'Barry',
    'Bart',
    'Camden',
    'Cameron',
    'Cara',
    'Carla',
    'Carlos',
    'Carmen',
    'Carol',
    'Delia',
    'Dennis',
    'Derek',
    'Eddie',
    'Edith',
    'Edmund',
    'Edna',
    'Eileen',
    'Frank',
    'Franklin',
    'Garry',
    'Gavin',
    'Gemma',
    'Gerald',
    'Gina',
    'Ginger',
    'Gino',
    'Giovanni',
    'Gladys',
]

export default ListOfRandomNames