// import { bufferToHex } from 'ethereumjs-util'
// import { encrypt } from '@metamask/eth-sig-util'
import { jsPDF } from "jspdf";
// import currentAuthUser, {currentAuthUserPublicKey} from './currentAuthUser'
import { ethers } from 'ethers'

// /**
//  *  August 17th 2022
//  *  William Doyle
//  *  makeReceiptPDF 
//  */
// export default async function makeReceiptPDF(challengeDataToShow: any, solution: string, cd: any) {
//     const usr_pub = await currentAuthUserPublicKey()
//     const userAddress = await currentAuthUser()

//     const encryptedMessage = bufferToHex(
//         Buffer.from(
//             JSON.stringify(
//                 encrypt({
//                     publicKey: usr_pub,
//                     data: solution,
//                     version: 'x25519-xsalsa20-poly1305',
//                 })
//             ),
//             'utf8'
//         )
//     );

//     const doc = new jsPDF();
//     doc.text(doc.splitTextToSize(
//         `Solution To Proof Of Quantum challenge ${challengeDataToShow.position} at ${cd.address} on ${cd.blockchain.name}.\n\nsolved by ${userAddress} and encrypted with the public key ${usr_pub} \n${'-'.repeat(80)}\n\n${encryptedMessage}\n\n${'-'.repeat(80)}\n\nuse eth_decrypt to decrypt`, 180), 10, 10);
//     doc.save(`encrypted_solution.pdf`);
// }


/**
 * @name unsafeExport
 * @author William Doyle
 * @date August 2022
 * @param challengeDataToShow 
 * @param solution 
 * @param cd 
 */
export async function unsafeExport(challengeDataToShow: any, solution: string, cd: any) {
    const doc = new jsPDF();
    const w : ethers.Wallet = new ethers.Wallet(solution)
    doc.text(doc.splitTextToSize(`Address: "${w.address}"\nPrivate Key: "${solution}"`, 180), 10, 10);
    doc.save(`PROOF_OF_QUANTUM_challenge_solution_${cd.blockchain.chainid}_${challengeDataToShow.position}.pdf`);
}