import { ethers } from 'ethers'

export default class ProviderSingleton {
    private static instance: ethers.providers.Web3Provider
    private constructor() { }

    static async getInstance(): Promise<ethers.providers.Web3Provider> {
        if (!ProviderSingleton.instance) {
            ProviderSingleton.instance = new ethers.providers.Web3Provider((window as any).ethereum)
            await ProviderSingleton.instance.send("eth_requestAccounts", [])
        }
        return ProviderSingleton.instance;
    }
}