import currentAuthUser from "../../functions/currentAuthUser";
import { newSignedHash2 } from "../tools/GenerateSolutionRedeemer";
import { ethers } from "ethers";
import callContractFunction from "../../functions/callContractFunction";
import Dev from "../../classes/Dev";

/*
    processDatum
    William Doyle
    June 10th 2022
*/
export function processDatum(datum: any): string {
    if (datum === undefined || datum === null)
        return "Loading..."

    if (typeof datum === "number")
        return datum.toString()

    const l: number = datum.length
    if (l > 20)
        return datum.substring(0, 7) + "..." + datum.substring(l - 5)
    return datum
}

/*
    pubkey_of_puzzle_to_solver_address
    William Doyle
    June 10th 2022
    takes the pubkey of a puzzle and a processed list of results from contract.getDataPoints (provided by redux.. loaded in Scoreboard)
    returns the address of the solver or 'NA' if the puzzle was not solved yet
*/
export function pubkey_of_puzzle_to_solver_address(pubkey: string, dataPoints: any): string {
    if (dataPoints === null)
        return "Loading..."

    // find the entry by looking up the pubkey in the dataPoints
    const entry = dataPoints.find((entry: any) => entry.pubkey === pubkey)

    if (!entry)
        return 'NA'
    return entry.solvedBy
}

/*
    pubkey_of_puzzle_to_token_url
    William Doyle
*/
export function pubkey_of_puzzle_to_token_url(pubkey: string, dataPoints: any): string {
    if (dataPoints === null)
        return "Loading..."

    // find the entry by looking up the pubkey in the dataPoints
    const entry = dataPoints.find((entry: any) => entry.pubkey === pubkey)

    if (!entry)
        return 'NA'
    return entry.asset_url
}


export async function mintSolution(solution: string, challengeIndex: number, contract: ethers.Contract) {
    Dev.log(`inside mint solution`)
    const user: string = await currentAuthUser()
    const sig = await newSignedHash2(user, new ethers.Wallet(solution))
    Dev.log(`About to call mint with the following details:\n\t1. messageHash\t\t${sig.messageHash}\n\t2. signature\t\t${sig.flatSig}\n\t3. challengeIndex\t${challengeIndex}\n\t4. user\t\t\t\t${user}\n\t5. solution\t\t\t${solution}`)
    await callContractFunction(contract, 'mint', [sig.messageHash, sig.flatSig, challengeIndex, user.substring(0, 7)])
}