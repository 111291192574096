/*
    A placeholder SVG to indicate loading.
    William Doyle
*/

const loading_svg: string = ` <svg height="30" width="200">
  <text x="0" y="15" fill="red">Invalid Data!</text>
</svg> `

export default loading_svg
