
type Props = {
    IF : boolean,
    THEN : React.ReactElement,
    OTHERWISE: React.ReactElement
}

export default function Maybe({IF, THEN, OTHERWISE = <></>}:Props) {
    if (IF) 
        return THEN
    return OTHERWISE
} 