
import { createSlice } from '@reduxjs/toolkit'

const testSlice = createSlice({
    name: 'testSlice',
    initialState: {
        value: 0,
    },
    reducers: {
        grow: (state:any) => {
            state.value += 1
        },
        shrink: (state:any) => {
            state.value -= 1;
        },
        incrementByAmount: (state:any, action:any) => {
            state.value += action.payload
        },
    }
})
export default testSlice
export const { grow, shrink, incrementByAmount } =testSlice.actions