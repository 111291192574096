import React from 'react';
import BlockchainModel from '../classes/BlockchainModel';
import blockchains from '../constants/blockchains';

/**
 * @author William Doyle
 * @date August 25th 2022
 */
export default function useMaintainChain(__chainId: string) {
  const chainId = `0x${__chainId.trim()}`

  React.useEffect(() => {
    (async () => {
      if ((window as any).ethereum.networkVersion !== parseInt(chainId).toString()) 
        await (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }]
        }).catch(async (err: any) => {
          if (err.code === 4902) {
            const chainObj: BlockchainModel = Object.values(blockchains).find((chain: BlockchainModel) => chain.chainid.toString(16) === __chainId) as BlockchainModel;

            await (window as any).ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: chainObj.name,
                  chainId: chainId,
                  nativeCurrency: { name: chainObj.nativeCurrency, decimals: 18, symbol: chainObj.nativeCurrency },
                  rpcUrls: chainObj.rpcUrls,
                }
              ]
            });
          }
        })
    })();
  }, [__chainId, chainId])
}
