import store from "../redux/store";

/**
 *  currency_symbol
 *  William Doyle
 *  August 3rd 2022 
 */
export default function currency_symbol(): string {
    const state = store.getState()
    return state?.contractData?.contractData?.['blockchain']?.nativeCurrency ?? `¢`
}