export default function makeInviteText (challengeDetails: any ) : string {
    return `<h1>Check out challenge ${challengeDetails?.position ?? ''} at <a href="https://proof-of-quantum.com/?position=${challengeDetails?.position ?? ''}">proof-of-quantum.com</a></h1><br/><h2>challenge details</h2><br> 
    <table border="1">
        <tr>
            <td>position</td>
            <td>${challengeDetails?.position ?? ''}</td>
        </tr>
        <tr>
            <td>Public Key</td>
            <td>${challengeDetails?.pubkey ?? ''}</td>
        </tr>
        <tr>
            <td>hint</td>
            <td>${challengeDetails?.hint ?? ''}</td>
        </tr>
    </table>`
}