import DataTable from "../../classes/DataTable";

export const initialDataTable: DataTable = [
    {
        difficulty: 0,
        pubkey: "Loading...",
        hint: "Loading...",
        reward: "Loading...",
        challenger: "Loading...",
        position: "Loading...",
        sponsorships: '0',
    }
]