import BlockchainModel from "../classes/BlockchainModel"

type BlockchainCollection = {
    // testnets
    moonbase: BlockchainModel,
    // rinkeby: BlockchainModel,
    ropsten: BlockchainModel,
    // mainnets
    ethereum: BlockchainModel,
    binance: BlockchainModel,
    moonbeam: BlockchainModel,
    moonriver: BlockchainModel,
    milkomeda: BlockchainModel,
    polygon: BlockchainModel,
    klaytn: BlockchainModel,
}

const blockchains: BlockchainCollection = {
    moonbase: {
        name: 'Moonbase alpha',
        chainDescription: 'Test chain for moonriver',
        explorerBaseUrl: 'https://moonbase.moonscan.io/address/',
        chainid: 1287,
        nativeCurrency: 'DEV',
        isTestNet: true,
        rpcUrls: [`https://rpc.api.moonbase.moonbeam.network`],
    },
    // rinkeby: {
    //     name: 'Rinkeby',
    //     chainDescription: 'EVM compatible Test chain',
    //     explorerBaseUrl: 'https://rinkeby.etherscan.io/address/',
    //     chainid: 4,
    //     nativeCurrency: 'RIN',
    //     isTestNet: true,
    //     rpcUrls: [`https://rinkeby.infura.io/v3/`],
    // },
    ropsten: {
        name: 'Ropsten',
        chainDescription: 'EVM compatible Test chain',
        explorerBaseUrl: 'https://ropsten.etherscan.io/address/',
        chainid: 3,
        nativeCurrency: 'ETH',
        isTestNet: true,
        rpcUrls: [`https://ropsten.infura.io/v3/`],
    },
    ethereum: {
        name: 'Ethereum',
        chainDescription: 'Ethereum mainnet',
        explorerBaseUrl: '',
        chainid: 1,
        nativeCurrency: 'ETH',
        isTestNet: false,
        rpcUrls: [`https://mainnet.infura.io/v3/`],
    },
    binance: {
        name: 'Binance Smart Chain',
        chainDescription: 'Binance Smart Chain from Binance',
        explorerBaseUrl: '',
        chainid: 56,
        nativeCurrency: 'BNB',
        isTestNet: false,
        rpcUrls: [`https://bsc-dataseed.binance.org`],
    },
    moonbeam: {
        name: 'Moonbeam',
        chainDescription: 'Moonbeam mainnet',
        explorerBaseUrl: '',
        chainid: 1284,
        nativeCurrency: 'GLMR',
        isTestNet: false,
        rpcUrls: [`https://rpc.api.moonbeam.network`],
    },
    moonriver: {
        name: 'Moonriver',
        chainDescription: 'Moonriver mainnet',
        explorerBaseUrl: '',
        chainid: 1285,
        nativeCurrency: 'MOVR',
        isTestNet: false,
        rpcUrls: [`https://rpc.api.moonriver.moonbeam.network`],
    },
    milkomeda: {
        name: 'Milkomeda',
        chainDescription: 'Milkomeda a EVM sidechain for cardano',
        explorerBaseUrl: '',
        chainid: 2001,
        nativeCurrency: 'mADA',
        isTestNet: false,
        rpcUrls: [`https://rpc-mainnet-cardano-evm.c1.milkomeda.com`],
    },
    polygon: {
        name: 'Polygon',
        chainDescription: 'Polygon mainnet',
        explorerBaseUrl: 'https://polygonscan.com/address/',
        chainid: 137,
        nativeCurrency: 'MATIC',
        isTestNet: false,
        rpcUrls: [`https://polygon-rpc.com`],
    },
    klaytn: {
        name: 'Klaytn',
        chainDescription: 'Klaytn mainnet',
        explorerBaseUrl: '',
        chainid: 8217,
        nativeCurrency: 'KLAY',
        isTestNet: false,
        rpcUrls: [`https://klaytn-mainnet-rpc.allthatnode.com:8551`,
            `https://public-node-api.klaytnapi.com/v1/cypress`
        ],
    }
}

export default blockchains