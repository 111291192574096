import { configureStore} from '@reduxjs/toolkit'
import contractDataSlice from './slices/contractDataSlice';
import testSlice from './slices/testSlice';
import generatedInputValueSlice from './slices/generatedInputValueSlice';
import callDataSlice from './slices/callDataSlice';
import dataPointsSlice from './slices/dataPointsSlice';
import challengeDisplaySlice from './slices/challengeDisplaySlice';
import challengeModalSlice from './slices/challengeModalSlice';
import sponsorModalSlice from './slices/sponsorModalSlice';
import largeChallengeModalSlice from './slices/largeChallengeModalSlice';
import solutionSlice from './slices/solutionSlice';

export default configureStore({
    reducer: {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        test: testSlice.reducer,
        contractData: contractDataSlice.reducer,
        generatedInputValue: generatedInputValueSlice.reducer,
        callData: callDataSlice.reducer,
        dataPoints: dataPointsSlice.reducer,
        challengeDisplay: challengeDisplaySlice.reducer,
        challengeModal: challengeModalSlice.reducer,
        sponsorModal: sponsorModalSlice.reducer,
        largeChallengeModal: largeChallengeModalSlice.reducer,
        solutionInfo: solutionSlice.reducer,
        // global: (state: any, action: any) => {
        //     if (state === undefined)
        //         return null;
        //     switch (action.type) {
        //         case 'growTest':
        //             console.log('grow test');
        //             return;
        //         default: return state
        //     }
        // }
    },
})