// const color_sets = [
//     {
//         'zero': `#${Math.floor(Math.random() * 0xffffff).toString(16)}`,
//         'one': `#${Math.floor(Math.random() * 0xffffff).toString(16)}`,
//     },
//     {
//         'zero': `#${Math.floor(Math.random() * 0xffffff).toString(16)}`,
//         'one': `#${Math.floor(Math.random() * 0xffffff).toString(16)}`,
//     },
//     {
//         'zero': `#${Math.floor(Math.random() * 0xffffff).toString(16)}`,
//         'one': `#${Math.floor(Math.random() * 0xffffff).toString(16)}`,
//     },
// ]

const color_sets = [
    {
        'zero': '#ddaaaa',
        'one': '#663333'
    },
    {
        'zero': '#aaddaa',
        'one': '#336633'
    },
    {
        'zero': '#aaaadd',
        'one': '#333366'
    }
]

export default color_sets