import { ethers } from 'ethers';
import calculateSolutionRedeemer from "./calculateSolutionRedeemer";

function getPubKey(wallet: ethers.Wallet) {
    return "0x" + (new ethers.utils.SigningKey(wallet.privateKey)).publicKey.substring(4);
}

const generatePuzzle = (n: number = 8) => {
    const w : ethers.Wallet = ethers.Wallet.createRandom();
    const pub = getPubKey(w);
    // the clue is the private key with the last n characters replaced with zeros
    // const clue = w.privateKey.substring(0, w.privateKey.length - n) + '0'.repeat(n);
    const pri_hex : string = w.privateKey
    
    console.log(`${'-'.repeat(100)}pri_hex --> `, pri_hex)
    // const pri_bn : BN = new BN(pri_hex, 16)
    console.log(`stub`)
    // console.log(`pri_bn --> `, typeof pri_bn)

    const clue = w.privateKey.substring(0, w.privateKey.length - n) + '0'.repeat(n);
    return {
        puzzle: [pub, clue],
        solution: w.privateKey
    }
}

const proofSigs: any[] = [];

export function getProofSigs() {
    console.log("proofSigs: ", proofSigs);
    return proofSigs
}


const f_options = [
    (i : number) => generatePuzzle((i + 3) % 9),
    (i : number) => generatePuzzle((i % 9) + 3),
    (i : number) => generatePuzzle(0),
    // (i : number) => generatePuzzle((i % 60) + 1),
    // (i : number) => generatePuzzle(((i + 30) % 60) + 1),
]


/*
    newInitCallData
    creates the puzzles to be posted to the contract
*/
export default async function newInitCallData() {
    const initial = []
    const amount = 12

    const f = f_options[Math.floor(Math.random() * f_options.length)]

    for (let i = 0; i < amount; i++) {
        const { puzzle, solution } = f(i)
        initial.push(puzzle)
        proofSigs.push(await calculateSolutionRedeemer(solution))
        console.log(i)
    }
    return initial
}