import { createSlice } from '@reduxjs/toolkit'

const callDataSlice = createSlice({
    name: 'callDataSlice',
    initialState: {
        callData : null,
        messageValue: 0,
    },
    reducers: {
        setCallData: (state:any, action:any) => {
            state.callData = action.payload
        },
        setMessageValue: (state:any, action:any) => {
            const payload : number = action.payload
            state.messageValue = payload
        }
    }
})
export default callDataSlice
export const { setCallData, setMessageValue } = callDataSlice.actions