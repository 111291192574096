import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { open, focusOnChallenge } from '../../redux/slices/largeChallengeModalSlice'
import RenderSvg from "../RenderSvg/RenderSvg";
import * as elliptic from "elliptic"
import DataRow from '../../classes/DataRow';
import DataTable from '../../classes/DataTable';
import useSmartContract from '../../hooks/useSmartContract';
import calc_difficulty from '../../functions/calc_difficulty';
import { mintSolution, pubkey_of_puzzle_to_solver_address, pubkey_of_puzzle_to_token_url } from '../ListOfChallenges/functions';
import { ethers } from "ethers";
import count_unknown_digit from '../../functions/count_unknown_digit';
import { Button } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import * as sponsorModalState from "../../redux/slices/sponsorModalSlice"
import solvePuzzle from '../../functions/solvePuzzle';
import * as challengeModalState from '../../redux/slices/challengeModalSlice'
import bn from "bn.js"


const eth_pub_to_std_pub = (pubkey: string) => `04${pubkey.substring(2)}`
const ec = new elliptic.ec('secp256k1')
const __expected_pub_key_length__ = 132

export default function LargeChallengeModal() {
    const isOpen = useSelector((state: any) => state.largeChallengeModal.isOpen)
    const challengeDataToShow = useSelector((state: any) => state.largeChallengeModal.focusedChallenge)
    const queryParams = React.useMemo(() => new URLSearchParams(window.location.search), []);
    const dispatch = useDispatch()
    const rawData = useSmartContract("getAllChallenges")
    const dataPoints = useSelector((state: any) => state.dataPoints.dataPoints)
    const cd = useSelector((state: any) => state.contractData.contractData) // contract data
    const [balance, setBalance] = React.useState<string>('0')
    const [clue, setClue] = React.useState<string>('Loading...')

    /**
     * 
     */
    React.useEffect(() => {
        const position = queryParams.get('position')
        if (position !== null) {
            if (rawData === null)
                return

            const processedData: DataTable = rawData.map((row: any, index: number) => {
                const rval = ({
                    index: index,
                    difficulty: calc_difficulty(row.currentPuzzle.knownBytes), // TODO: calculate with the same function used in the scoreboard
                    clue_size: row.currentPuzzle.knownBytes,
                    pubkey: row.currentPuzzle.publicKey,
                    hint: row.currentPuzzle.knownBytes,
                    reward: pubkey_of_puzzle_to_token_url(row.currentPuzzle.publicKey, dataPoints),
                    challenger: pubkey_of_puzzle_to_solver_address(row.currentPuzzle.publicKey, dataPoints),
                    position: row.position.toString(),
                })
                return rval
            }).filter((el: any | null) => el !== null)

            const wantedRow: DataRow | undefined = processedData.find((el: DataRow) => el.position === position)

            if (wantedRow === undefined)
                throw new Error("Could not find the row with the given position")

            dispatch(focusOnChallenge(wantedRow))
            dispatch(open())
        }
    }, [queryParams, dispatch, dataPoints, rawData])


    /**
     * When the challenge data changes, we need to update the balance.                                              
     * William Doyle
     * July 5th 2022
     */
    React.useEffect(() => {
        if (challengeDataToShow === null)
            return

        (async () => {
            const formatedKey = `0x04${challengeDataToShow.pubkey.substring(2)}`
            if (formatedKey.length !== __expected_pub_key_length__)
                return
            const address: string = ethers.utils.computeAddress(formatedKey)
            const provider = new ethers.providers.Web3Provider((window as any).ethereum);
            const _balance = await provider.getBalance(address);
            setBalance(ethers.utils.formatEther(_balance))
        })()
    }, [challengeDataToShow])


      React.useEffect(() => {
        if (challengeDataToShow === null)
            return

        if (challengeDataToShow.hint.length < 16)
            return

        // const hint_bn = ethers.BigNumber.from(challengeDataToShow.hint.substring(2))
        // const hint_bn = ethers.BigNumber.from(challengeDataToShow.hint)
        const hint_bn = new bn(challengeDataToShow.hint.substring(2), 16) 
        const hint_bin = hint_bn.toString(2).padEnd(256, '0')
        // const hint_bin = hint_bn.toString().padEnd(256, '0')
        // const hint_bin = parseInt(hint_bn.toString()).toString(2).padEnd(256, '0')
        // Dev.log(`hint_bin: ${hint_bin}`)
        setClue(hint_bin)
    } , [challengeDataToShow] ) 

    if (!((isOpen) && (challengeDataToShow !== null)))
        return <></>
    // if (challengeDataToShow === null)
    //     return <>...loading challenge data. Please wait...</>


    // const clue: string = (() => {
    //     if (challengeDataToShow.hint === "Loading...")
    //         return "Loading..."

    //     const temp_keys = ec.keyFromPrivate(challengeDataToShow.hint)
    //     const temp_pri: BN = temp_keys.getPrivate()
    //     const temp_pri_bin: string = temp_pri.toString(2)
    //     const temp_pri_bin_pad: string = temp_pri_bin.padStart(256, '0')
    //     return temp_pri_bin_pad
    // })()

    return <div className="large-challenge-modal">
        <div className='large-challenge-modal-child'>
            <RenderSvg
                x={ec.keyFromPublic(eth_pub_to_std_pub(challengeDataToShow.pubkey), 'hex').getPublic().getX().toString(2).padStart(256, '0')}
                y={ec.keyFromPublic(eth_pub_to_std_pub(challengeDataToShow.pubkey), 'hex').getPublic().getY().toString(2).padStart(256, '0')}
                clue={clue}
                scale={8}
            />
        </div>
        <div className='large-challenge-modal-child'>
            <strong>
                <h1>
                    {`Challenge ${challengeDataToShow.position} `}
                </h1>
            </strong>
            <div>
                {(() => {
                    const formatedKey = `0x04${challengeDataToShow.pubkey.substring(2)}`
                    const address: string = ethers.utils.computeAddress(formatedKey)
                    return <a href={`${cd?.blockchain?.explorerBaseUrl}${address}`} target="_blank" rel="noopener noreferrer">{address}</a>
                })()}
            </div>
            {`Difficulty ${count_unknown_digit(challengeDataToShow.hint) * 4} bits`}
            <br />
            {/* {`Sponsored reward ₿ ${balance} `} */}
            {`Sponsored reward ¢${balance} `}
            {
                (() => {
                    if (challengeDataToShow.challenger === "Loading...")
                        return <Button variant="outlined" color="primary" disabled>Loading...</Button>
                    if (challengeDataToShow.challenger !== "NA")
                        return <div className="challenge-action-buttons">
                            <button id="widebtn" disabled>
                                <SearchIcon />
                                Solve
                            </button>
                            <button id="widebtn" disabled>
                                <AttachMoneyIcon />
                                Sponsor
                            </button>
                            <button id="widebtn" disabled>
                                <GroupIcon />
                                Challenge
                            </button>
                        </div>
                    return <div className="challenge-action-buttons">
                        <button
                            id="widebtn"
                            onClick={async () => mintSolution(await solvePuzzle(challengeDataToShow.pubkey, challengeDataToShow.hint), parseInt(challengeDataToShow.position), new ethers.Contract(cd.address, cd.abi))}>
                            <SearchIcon />
                            Solve
                        </button>
                        <button
                            id="widebtn"
                            onClick={() => {
                                dispatch(sponsorModalState.focusOnChallenge(challengeDataToShow))
                                dispatch(sponsorModalState.open())
                            }}
                        >
                            <AttachMoneyIcon />
                            Sponsor
                        </button>
                        <button id="widebtn" onClick={() => {
                            dispatch(challengeModalState.focusOnChallenge(challengeDataToShow))
                            dispatch(challengeModalState.open())
                        }}>
                            <GroupIcon />
                            Challenge
                        </button>
                        {/* <button id="widebtn" onClick={() => navigator.clipboard.writeText(`//position: ${challengeDataToShow.position}\n//hint: ${challengeDataToShow.hint}\nconst p = \`${build_pari_program(challengeDataToShow.pubkey, challengeDataToShow.hint)[0]}\``)}>
                        <GroupIcon />
                        Copy Solution Program
                    </button> */}
                    </div>
                })()
            }


        </div>
    </div>
}
