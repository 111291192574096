import React from 'react';
import useSmartContract from '../../hooks/useSmartContract';
import { ethers } from 'ethers';


/*
    TokenView
    An abstract visualization of the tokens owned by an address
    William Doyle
    July 13th 2022
*/
export default function TokenView(props : any) {
    const collection = useSmartContract('byOwner', props?.address === '' ? `0x${'0'.repeat(40)}` : props?.address);
    const [numTokens, setNumTokens] = React.useState<string>('');
    const [tokenIds, setTokenIds] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (collection === null)
            return;
        setNumTokens(collection[0].toString())
        setTokenIds(collection[1].map((id: any) => id.toString()))
    }, [collection])

    if (numTokens === '' || numTokens === '0')
        return <></>

    return <div className="token-view">
        <div className='token-view-count-display'>
            {numTokens}
        </div>
        <div className='token-view-list-ids'>
            {
                tokenIds.map((id: any) => <div key={ethers.utils.id(`short-tkn-view:${id}`)} className="token-view-single"> {id} </div>)
            }
        </div>
    </div>
}